import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { getCampaignById, useBookingsByCampaignId, useFreshTalent, UserContext } from '../services/firebase';
import { Campaign, Booking } from '../shared/types';
import accounting from 'accounting';
import grailUtil from '../services/grailUtil';

//
import './CampaignDetails.scss';
import Layout from '../components/Layout';

//
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import StatusCell from '../components/grid/StatusCell';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


export default function CampaignDetailsSimplePage() {
    const { campaignId } = useParams<{ campaignId: string }>();
    const user = useContext(UserContext);
    const [campaign, setCampaign] = useState<Campaign>();
    // const [talent, setTalent] = useState<Talent[]>([]);
    const bookings: Booking[] = useBookingsByCampaignId(campaignId);

    const talentQuery = useFreshTalent({ requestByManager: true, includeDeleted: true });
    const talent = talentQuery.flatData;

    useEffect(() => {
        getInitialProps();

        async function getInitialProps() {
            if (campaignId) {
                getCampaignById(campaignId).then((campaignRes) => setCampaign(campaignRes));
            }
        }
    }, [campaignId]);

    const columns: GridColDef[] = [
        {
            field: 'talentId', 'headerName': 'Creator', width: 150,
            renderCell: (params) => {
                const curTal = talent.find((tal) => tal.id === params.value);
                console.log(`curTal`, curTal);
                // console.log(`curTal`, curTal);
                if (curTal?.tiktokUser) {
                    return <a href={`https://www.tiktok.com/@${curTal?.tiktokUser}`} target="_blank" rel="noreferrer">@{curTal?.tiktokUser}</a>
                } else if (curTal?.instagramUser) {
                    return <a href={`https://instagram.com/${curTal?.instagramUser}`} target="_blank" rel="noreferrer">@{curTal?.instagramUser}</a>
                } else if (params.row.talentName) {
                    return '@' + params.row.talentName;
                    // return "Nope";
                }
                return null;
            }
        },
        {
            field: 'manager', headerName: 'Manager', width: 150,
            renderCell: (params) => {
                const managers = talent.find((tal) => tal.id === params.row.talentId)?.managers;

                if (managers?.[0]) {
                    return <div>
                        <div>{managers[0].firstName} {managers[0].lastName}</div>
                        <div><a href={`mailto:${managers[0].email}`}>{managers[0].email}</a></div>
                    </div>
                }
                return <></>
            }
        },
        { field: 'status', 'headerName': 'Status', width: 150, renderCell: StatusCell },
        {
            field: 'price', 'headerName': 'Price', width: 150, align: 'right',
            valueGetter: (params) => params.value ? accounting.formatMoney(params.value, grailUtil.getCurrencySymbol(params.row.currency), 2, ",", ".") : null,
        },
        // { field: 'currency', 'headerName': 'Currency', width: 150 },
        // { field: 'videoLink', 'headerName': 'Video URL', width: 150 },
        { field: 'videoLink', headerName: 'Video Link', type: 'string', aggregable: false, width: 200, editable: false, renderCell: (params) => params.value ? <a className="text-decoration-none" href={params.value} rel="noreferrer" target="_blank">{params.value}</a> : null },
        { field: 'boostCode', 'headerName': 'Boost Code', width: 150 },
        { field: 'invoiceLink', headerName: 'Invoice Link', type: 'string', aggregable: false, width: 200, editable: false, renderCell: (params) => params.value ? <a className="text-decoration-none" href={params.value} rel="noreferrer" target="_blank">{params.value}</a> : null },
    ];

    return (
        <>
            <Layout gray>
                <div className="container-fluid mt-200 mb-200">
                    <h1 className="mb-100">{campaign?.campaignName}</h1>

                    {/* <div className="mb-100">Booked by <a href={`/edit-user/${campaign?.booker?.uid}`} target="_blank" rel="noreferrer">{campaign?.booker?.firstName} {campaign?.booker?.lastName}</a> at <strong>{campaign?.booker?.company}</strong></div> */}

                    <div className="row-fluid no-padding">
                        <div className="col col-md-8 col-lg-6">
                            <Accordion defaultExpanded={user?.roles?.externalManager}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <strong>Campaign Details</strong>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="CampaignDetailsSummary">
                                        {
                                            user?.roles?.manager ? (
                                                <>
                                                    <div className="flex justify-content-between mb-25">
                                                        <strong>Booker</strong>
                                                        <div><a href={`/edit-user/${campaign?.booker?.uid}`} target="_blank" rel="noreferrer">{campaign?.booker?.firstName} {campaign?.booker?.lastName}</a></div>
                                                    </div>

                                                    <div className="flex justify-content-between mb-25">
                                                        <strong>Client</strong>
                                                        <div>{campaign?.booker?.company}</div>
                                                    </div>
                                                </>
                                            ) : null
                                        }

                                        {campaign?.soundUrl ? (
                                            <div className="flex justify-content-between mb-25">
                                                <strong>Sound URL</strong>
                                                <div><a href={campaign.soundUrl} target="_blank" rel="noreferrer">{campaign.soundUrl}</a></div>
                                            </div>) : null}

                                        {campaign?.fileUrl ? (
                                            <div className="flex justify-content-between mb-25">
                                                <strong>Brief File</strong>
                                                <div><a href={campaign.fileUrl} target="_blank" rel="noreferrer">Brief File</a></div>
                                            </div>) : null}

                                        <div>
                                            <div className="mb-50 font-weight-bold">Details</div>
                                            <div>{campaign?.details}</div>
                                        </div>

                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        {
                            user?.roles?.booker ? (
                                <div className="col col-lg-6 col-md-4">
                                    <div className="font-weight-bold mb-25">Questions?</div>
                                    <div>Contact Ella, our Booking Platform Manager at <a className="link alternate-clean" href="mailto:ella@grail-talent.com" target="_blank" rel="noreferrer">ella@grail-talent.com</a>.</div>
                                </div>) : null}
                    </div>

                </div>

                {user?.roles?.manager || user?.roles?.booker ? (
                    <div className="container-fluid mb-300">
                        <DataGridPremium
                            throttleRowsMs={200}
                            showCellVerticalBorder
                            columns={columns}
                            rows={bookings}
                            autoHeight
                            hideFooter
                            sx={{
                                // border: 'none',
                                backgroundColor: '#fff',
                                '& .MuiDataGrid-columnHeaderTitle': {
                                    fontWeight: 700,
                                },
                                '& .MuiDataGrid-toolbarContainer': {
                                    padding: '1rem 0',
                                },
                                '& .MuiButton-textPrimary': {
                                    height: '2rem',
                                    borderRadius: '1rem',
                                    padding: '0 0.75rem',
                                    color: '#000',
                                    border: '1px solid #62edff',
                                    margin: '0.25rem 0.5rem 0.25rem 0',
                                    '&:hover': {
                                        'backgroundColor': 'rgba(98, 237, 255, 0.3)'
                                    }
                                }
                            }}
                        />
                    </div>
                ) : null}
            </Layout>
        </>
    );
}