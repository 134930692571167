import { GridEditInputCell, GridRenderEditCellParams, GridPreProcessEditCellProps } from '@mui/x-data-grid-premium';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import accounting from 'accounting';

export function CellWithValidation(props: GridRenderEditCellParams) {
    return (
        <div>
            <div>
                {
                    props.colDef.field === 'price' ?
                        <GridEditInputCell {...{ ...props, value: props.value ? accounting.unformat(props.value) : null }} />
                        : <GridEditInputCell {...props} />
                }
            </div>
            <Box sx={{ width: '100%' }}>
                <Tooltip componentsProps={{
                    tooltip: {
                        sx: {
                            bgcolor: '#E02B5F',
                            '& .MuiTooltip-arrow': {
                                color: '#E02B5F',
                            },
                        },
                    },
                }} open={!!props.error} title={props.errormessage || ''} arrow>
                    <div></div>
                </Tooltip>
            </Box>
        </div>
    );
}

export function validateNumber(params: GridPreProcessEditCellProps) {
    let error = false;
    let errormessage;

    let newVal = cleanNumber(params.props.value); // Cast as a number
    if (params.props.value && Number.isNaN(newVal)) {
        error = true;
        errormessage = (
            <div>
                <div>Must be a number with no currency.</div>
            </div>
        );
    } else if (!params.props.value) {
        // Do nothing if it's empty
    } else {
        params.props.value = newVal; // set the number, so that on save it's saved as a number...
    }

    return { ...params.props, error: error, errormessage: errormessage };

    function cleanNumber(stringNum: string | number) {
        if (typeof stringNum === 'string') {
            stringNum = stringNum.replaceAll(',', '');
        }
        return Number(stringNum);
    }
}

export function validateEmail(params: GridPreProcessEditCellProps) {
    let error = false;
    let errormessage;

    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (params.props.value && !params.props.value.match(regex)) {
        error = true;
        errormessage = (
            <div>
                <div>Enter a valid email address.</div>
            </div>
        );
    }
    return { ...params.props, error: error, errormessage: errormessage };
}

export function validateVideo(params: GridPreProcessEditCellProps) {
    let error = false;
    let errormessage;

    // const regex = /^(https:\/\/www\.tiktok\.com\/@[A-Za-z0-9-_.']*\/video\/[A-Za-z0-9-']*[0-9])/;
    const regex = /^(https:\/\/www\.tiktok\.com\/@[A-Za-z0-9-_.']*\/video\/[A-Za-z0-9-']*[0-9])|(https:\/\/www\.instagram\.com\/reel\/[A-Za-z0-9-']*)|(https:\/\/www\.instagram\.com\/p\/[A-Za-z0-9-']*)|(https:\/\/www\.instagram\.com\/stories\/[A-Za-z0-9-_.']*\/[A-Za-z0-9-']*)|(https:\/\/www\.youtube\.com\/shorts\/[A-Za-z0-9-_.']*)|(https:\/\/twitter\.com\/[A-Za-z0-9-_']*\/status\/[A-Za-z0-9-']*)|(https:\/\/www\.tiktok\.com\/@[A-Za-z0-9-_.']*\/photo\/[A-Za-z0-9-']*[0-9])|(https:\/\/www\.snapchat\.com\/spotlight\/[A-Za-z0-9-_']*)|(https:\/\/www\.youtube\.com\/[A-Za-z0-9-_'?=]*)/;

    if (params.props.value && !params.props.value.match(regex)) {
        error = true;
        errormessage = (
            <div>
                <div>Use any of the following formats:
                    <ul>
                        <li>https://www.tiktok.com/@user/video/1234567</li>
                        <li>https://www.tiktok.com/@user/photo/1234567</li>
                        <li>https://www.instagram.com/reel/CfpM3FtDoI0</li>
                        <li>https://www.instagram.com/p/CiiBfmdMQtp</li>
                        <li>https://www.instagram.com/stories/jackbeanuk/2935854530030546322</li>
                        <li>https://www.youtube.com/shorts/0T5yt0MzmdQ</li>
                        <li>https://twitter.com/whitest_injera/status/1621697248378687490</li>
                        <li>https://www.snapchat.com/spotlight/ABC123</li>
                    </ul>

                </div>
            </div>
        );
    }
    return { ...params.props, error: error, errormessage: errormessage };
}