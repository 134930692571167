import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { User } from '../shared/types';

interface ManagerSingleSelectProps {
    managers: User[],
    value?: string,
    onChange: (event: React.SyntheticEvent, value: string | null | undefined) => unknown,
    disabled?: boolean
}

const sx = {
    '& .MuiOutlinedInput-root': {
        padding: '0.375rem 0.75rem',
        height: 'calc(1.5em + 0.75rem + 2px)',
        '& .MuiAutocomplete-input': {
            padding: 'unset',
        },
        backgroundColor: '#fff'
    },
    '& .MuiFormControl-root': {
        margin: 0,
    },
    '& .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline': {
        // border: props.isErrored ? '1px solid red' : '1px solid #ced4da !important'
        border: '1px solid #ced4da !important',
    },
    '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
            //     border: props.isErrored ? '1px solid red' : '1px solid #ced4da !important'
            border: '1px solid #ced4da !important',
        }
    },
    '& .MuiOutlinedInput-input': {
        border: 'none !important'
    }
};

export default function ManagerSingleSelect(props: ManagerSingleSelectProps) {
    const { managers, value, onChange, disabled } = props;

    return (
        <Autocomplete
            disableClearable // don't show the clear button, otherwise it will accidentally set to all managers
            sx={sx}
            value={value}
            onChange={onChange}
            options={managers.map((manager) => manager.id)}
            renderOption={(props, managerId, { selected }) => (
                <li {...props}>
                    {managers.find((manager) => managerId === manager.id)?.email || ''}
                </li>
            )}
            renderInput={(params) => <TextField {...params} />}
            getOptionLabel={(managerId) => managers.find((manager) => managerId === manager.id)?.email || ''}
            disabled={disabled}
        />
    );
}