import { useState, useEffect, useContext } from 'react';
import { UserContext, useRelevantDocs, useFreshManagers, useSearchBookings } from '../services/firebase';

// Components
import Layout from '../components/Layout';
import { useGridApiRef } from '@mui/x-data-grid-premium';
// Styles
import './Bookings.scss';
import BookingsGrid from '../components/BookingsGrid';
import DataLoading from '../components/DataLoading';
import { Campaign, Client, Talent, User } from '../shared/types';


export default function BookingsSearch() {
    const user = useContext(UserContext);
    const apiRef = useGridApiRef();

    // Proof of Concept
    const [searchByField, setSearchByField] = useState('id');
    const [searchByValue, setSearchByValue] = useState('');
    // const [hasRefetched, setHasRefetched] = useState(false);

    // 1. Get bookings based on search params
    const bookings = useSearchBookings(searchByField, searchByValue);
    const shouldFetchSecondaryData = bookings.length > 0;

    // 2. Get Unique Ids Based on Bookings Array
    const campaignIds = [...new Set(bookings.filter((booking) => booking.campaignId).map((booking) => booking.campaignId!))];
    const bookerIds = [...new Set(bookings.filter((booking) => booking.bookerId).map((booking) => booking.bookerId!))];
    const talentIds = [...new Set(bookings.filter((booking) => booking.talentId).map((booking) => booking.talentId!))];
    const clientIds = [...new Set(bookings.filter((booking) => booking.clientId).map((booking) => booking.clientId!))];

    const bookers = useRelevantDocs<User>({ collectionName: 'users', docIds: bookerIds, enabled: shouldFetchSecondaryData }).data;
    const talent = useRelevantDocs<Talent>({ collectionName: 'talent', docIds: talentIds, enabled: !!bookers?.length && shouldFetchSecondaryData }).data;
    const clients = useRelevantDocs<Client>({ collectionName: 'clients', docIds: clientIds, enabled: !!talent?.length && shouldFetchSecondaryData }).data;
    const campaigns = useRelevantDocs<Campaign>({ collectionName: 'campaigns', docIds: campaignIds, enabled: !!clients?.length && shouldFetchSecondaryData }).data;

    // 3. REACT QUERY for managers/sales because we (maybe) need them all
    const managersQuery = useFreshManagers({ enabled: shouldFetchSecondaryData });
    const managers = managersQuery.flatData;
    const salesTeam = (managers || [])?.filter((manager) => manager.roles?.salesTeam);

    useEffect(() => {
        document?.body?.classList.add('overflow-x-hidden');
    }, []);

    return (
        <div className="BookingsPage">
            <Layout gray={true}>
                <div style={{ position: 'relative' }}>
                    <div style={{ position: 'absolute', top: '1rem', right: '1rem', zIndex: 2 }}>
                        <DataLoading />
                    </div>

                    <div className="container-fluid mt-100 flex align-items-center font-weight-semibold">
                        <div className="mr-50">
                            Search for bookings where:
                        </div>

                        <div className="form-group mr-50">
                            <select name="campaignType"
                                value={searchByField}
                                onChange={(e) => setSearchByField(e.target.value)}
                            >
                                <option value="airtableId">Airtable Booking ID</option>
                                <option value="id">Platform Booking ID</option>
                                <option value="campaignId">Platform Campaign ID</option>
                            </select>
                        </div>

                        <div className="mr-50">
                            =
                        </div>

                        <div className="form-group">
                            <input type="text" value={searchByValue} onChange={(e) => setSearchByValue(e.target.value)} />
                        </div>
                    </div>

                    <div style={{ position: 'relative' }}>
                        <BookingsGrid
                            apiRef={apiRef}
                            bookings={bookings}
                            bookers={bookers || []}
                            campaigns={campaigns || []}
                            clients={clients || []}
                            managers={managers}
                            salesTeam={salesTeam}
                            talent={talent || []}
                            user={user || {}}
                            searchMode={true}
                        />
                    </div>
                </div>
            </Layout>
        </div>
    );
}