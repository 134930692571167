import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton } from '@mui/x-data-grid-premium';

import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
// import DataLoading from "../DataLoading";

export default function NewCustomToolbar(props: any) {
    const { bookingId } = props;

    return (
        <div className="px-100 flex flex-wrap align-items-center justify-content-between">
            {/* <GridToolbar showQuickFilter /> */}

            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                {!bookingId && <GridToolbarFilterButton />}
                {/* <GridToolbarQuickFilter /> */}
                {/* <GridToolbarDensitySelector /> */}
                {/* <GridToolbarExport /> */}
                <Link className="hidden-sm-up" to="/new-booking">
                    <Button sx={{ 'border': '1px solid #62edff', 'height': '2rem', 'borderRadius': '1rem', 'color': 'black !important' }} variant="outlined" color="inherit">+ Booking</Button>
                </Link>
            </GridToolbarContainer>
        </div>
    );
}