import { updateDocField, updateDoc } from '../../services/firebase';
import firebase from "firebase/compat/app";
import { Campaign, Client, Talent, User, bookingStatusOptions } from '../../shared/types'; // User, Talent
import grailUtil from '../../services/grailUtil';

// const accounting = require("accounting");
import accounting from 'accounting';
import dayjs from 'dayjs';

// Components
import BookingActionButton from './BookingActionButton';
import { GridColDef, GridRenderEditCellParams, GridFilterInputValueProps, GridFilterOperator, getGridSingleSelectOperators } from '@mui/x-data-grid-premium';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { Link } from 'react-router-dom';
import Chip from '@mui/material/Chip';

//
import StatusCell from './StatusCell';
import CurrencyCell from './CurrencyCell';
import ChipOnClickCell from './ChipOnClickCell';

// Styles
// import './Bookings.scss';
import FileUpload from './FileUpload';
import TextAreaCell from './TextAreaCell';


import { TypeaheadSingleSelect, TypeaheadMultiSelect, SelectOption } from './Typeaheads';
import { CellWithValidation, validateEmail, validateNumber, validateVideo } from './CellWithValidation';

interface ColumnProps {
    apiRef?: React.MutableRefObject<GridApiPremium>,
    bookers: User[],
    campaigns: Campaign[],
    clients: Client[],
    managers: User[],
    salesTeam: User[],
    talent: Talent[],
    user: User,
}

export function generateColumns({ apiRef, bookers, campaigns, clients, managers, salesTeam, talent, user }: ColumnProps) {
    const monthOptions = grailUtil.createMonthOptions();

    ////////////////////////////////
    // Columns Definitions
    //////////////////////////////// 
    const columnId: GridColDef = { field: 'id', headerName: 'Grail Platform ID', width: 250, aggregable: false };
    const columnAirtableId: GridColDef = { field: 'airtableId', headerName: 'Airtable ID', width: 250, aggregable: false };
    const columnTalentName: GridColDef = {
        field: 'talentName', headerName: 'Talent', width: 150, aggregable: false,
        valueFormatter: (params) => params.value ? '@' + params.value : null, renderCell: (params) => ChipOnClickCell(params.value, user?.roles?.manager ? `/edit-talent/${params.row.talentId}` : null),
        valueGetter: (params) => {
            const curTal = talent?.find((tal) => tal.id === params.row.talentId);
            return curTal?.tiktokUser || curTal?.instagramUser || curTal?.youtubeUser || null
        }
    };
    const columnManagerIds: GridColDef = {
        field: 'managerIds',
        headerName: 'Manager',
        aggregable: false,
        filterOperators: createSingleSelectFilterOperators(managers || [], 'email'),
        renderCell: (params) => {
            return <>{(params.value as string[])?.map((managerId) => <div key={managerId}>
                <Link className="btn btn-tag" to={`/roster/${managerId}`} target="_blank" rel="noreferrer">{managers?.find((manager) => manager.id === managerId)?.email?.toLowerCase()}</Link>
            </div>)}</>
        }
    };
    const columnClient: GridColDef = {
        field: 'clientId',
        type: 'singleSelect',
        headerName: 'Client',
        width: 150,
        aggregable: false,
        filterOperators: createSingleSelectFilterOperators(getUniqueObjectsByKey(clients, "name"), 'name'), // TJN TO-DO: not lowerCase...
        renderCell: (params) => {
            const booker = bookers?.find((booker) => booker.id === params.row.bookerId); // check the clientId on the booker 1st, so it's up to date
            // check the clientId on the booking 2nd, just in case...
            const client: Client | undefined = clients?.find((client) => client.id === booker?.clientId || client.id === params.value);

            if (client) {
                return ChipOnClickCell(client.name || client.id || 'Client', `/edit-client/${client.id}`);
            } else if (params.row.clientId) {
                return ChipOnClickCell(params.row.clientName || params.row.clientId, `/edit-client/${params.row.clientId}`);
            } else if (params.row.clientName) {
                return params.row.clientName;
            } else {
                return (<></>);
            }
        },
        valueOptions: clients?.map((client) => ({ value: client.id!, label: client.name || client.id! })).sort(sortSelectOptions),
    };
    const columnBooker: GridColDef = {
        field: 'bookerId',
        type: 'singleSelect',
        headerName: 'Booker',
        width: 150,
        aggregable: false,
        renderCell: (params) => {
            const booker = bookers?.find((booker) => booker.id === params.value);
            if (booker) {
                return ChipOnClickCell(booker.email || '', `/edit-user/${booker.id}`);
            } else {
                return (<></>);
            }
        },
        filterOperators: createSingleSelectFilterOperators(getUniqueObjectsByKey(bookers, "email"), 'email'),
    };
    const columnCampaign: GridColDef = {
        field: 'campaign',
        headerName: 'Campaign (Type)',
        width: 150, aggregable: false,
        valueGetter: (params) => (params.row.campaignId || params.row.airtableType) ? campaigns.find((campaign) => campaign.id === params.row.campaignId)?.campaignName || params.row.airtableType || params.row.campaignId : null,
        renderCell: (params) => {
            const campaignId = params.row.campaignId;
            const campaign = campaignId ? campaigns.find((campaign) => campaign.id === campaignId) : null;
            const type = params.row.airtableType ? params.row.airtableType + " (Type)" : null;
            if (campaign) {
                return ChipOnClickCell(campaign.campaignName || '', `/campaign/${campaign.id}`);
            } else if (campaignId) {
                return ChipOnClickCell(type || campaignId, `/campaign/${campaignId}`);
            } else {
                return (
                    <>
                        {type || null}
                    </>
                );
            }
        }
    };
    const columnDocMeta: GridColDef = {
        field: 'docMeta', type: 'date', headerName: 'Date Created', width: 125,
        aggregable: false,
        // valueGetter: (params) => params.value ? new Date(params.value?.createTime?.toDate()) : null
        // If restoring from JSON...
        valueGetter: (params) => params.value?.createTime ?
            new firebase.firestore.Timestamp(params.value.createTime.seconds, params.value.createTime.nanoseconds)?.toDate() : null
    };
    const columnCreatedByManager: GridColDef = {
        field: 'createdByManager', headerName: "Created by Grail Team", type: 'boolean', aggregable: false, width: 150,
        valueGetter: (params) => params.value || params.row.lastUpdateByManager,
    };
    const columnMonthDue2: GridColDef = {
        field: 'monthDue2', headerName: 'Month Due', type: 'singleSelect', editable: true, width: 125, aggregable: false,
        valueOptions: monthOptions,
        valueGetter: (params) => params.row.id ? grailUtil.timestampToMonthDue(params.value) : null,
        valueSetter: (params) => { return { ...params.row, monthDue2: grailUtil.monthDueToTimestamp(params.value) } },
        groupingValueGetter: (params) => grailUtil.timestampToMonthDue(params.value),
        sortComparator: grailUtil.sortMonthOptions,
    };
    const columnCurrency: GridColDef = { field: 'currency', headerName: 'Currency', width: 100, renderCell: CurrencyCell };
    const columnPrice: GridColDef = {
        field: 'price', type: 'number', headerName: 'Booking Price', width: 150, align: 'right', editable: true,
        preProcessEditCellProps: validateNumber,
        renderEditCell: CellWithValidation,
        valueFormatter: (params) => params.value && apiRef?.current ? accounting.formatMoney(params.value, grailUtil.getCurrencySymbol(apiRef.current.getRow(params.id || '').currency), 2, ",", ".") : null,
    };
    const columnOverrideMicroRevenue: GridColDef = {
        field: 'overrideMicroRevenue', headerName: "Creator Commmission Override", type: 'number', aggregable: false, width: 150, editable: true,
        valueFormatter: ({ value, id }) => typeof value === 'number' && apiRef?.current ? grailUtil.getCurrencySymbol(apiRef.current.getRow(id || '').currency) + currencyFormat.format(value) : ""
    };
    const columnOverrideManagerCommission: GridColDef = {
        field: 'overrideManagerCommission', headerName: "Manager Commmission Override", type: 'number', aggregable: false, width: 150, editable: true,
        valueFormatter: (params: { value: any, id?: string | number }) => typeof params.value === 'number' && apiRef?.current ? grailUtil.getCurrencySymbol(apiRef.current.getRow(params.id || '').currency) + currencyFormat.format(params.value) : "",
    };

    // PERFORMANCE NOTE: moved this value calculation to outside the data grid to help with performance
    // And saw a huge gain
    const columnExternalCommission: GridColDef = {
        field: "externalCommission", headerName: "External Commission", editable: false,
        // valueGetter: externalCommissionValueGetter,
        // valueGetter: (params) => externalCommissionValueGetter(params, talent),
        valueFormatter: (params: { value: any, id?: string | number }) => typeof params.value === 'number' && apiRef?.current ? grailUtil.getCurrencySymbol(apiRef.current.getRow(params.id || '').currency) + currencyFormat.format(params.value) : "",
    };
    const columnOverrideRemoveCommissions: GridColDef = { field: "overrideRemoveCommissions", headerName: "Expenses: Remove Commission?", type: 'boolean', editable: true };
    const columnAgentCommission: GridColDef = {
        field: 'agentCommission', type: 'number', headerName: 'Commission', width: 150, align: 'right',
        valueGetter: agentCommissionValueGetter,
        valueFormatter: (params) => (params.value || typeof params.value === 'number') && apiRef?.current ? accounting.formatMoney(params.value, grailUtil.getCurrencySymbol(apiRef.current.getRow(params.id || '').currency), 2, ",", ".") : null,
        sortComparator: (a, b) => accounting.unformat(a) - accounting.unformat(b),
    };
    const columnStatus: GridColDef = {
        field: 'status', headerName: 'Status', width: 150, aggregable: false, type: 'singleSelect',
        renderCell: StatusCell,
        valueOptions: bookingStatusOptions.map((opt) => opt), filterOperators: [...getGridSingleSelectOperators(), createIsNotAnyFilterOperator(bookingStatusOptions.map((opt) => opt))]
    };
    const columnVideoLink: GridColDef = { field: 'videoLink', headerName: 'Video Link', width: 150, aggregable: false, editable: true, preProcessEditCellProps: validateVideo, renderEditCell: CellWithValidation, };
    const columnBoostCode: GridColDef = { field: 'boostCode', headerName: 'Boost Code', width: 150, aggregable: false, editable: true, type: 'string' };
    const columnBookingType: GridColDef = {
        field: 'bookingType', headerName: 'Special Booking Type', type: 'singleSelect', width: 150, aggregable: false, editable: true,
        valueOptions: ["downpayment", "event", "cancelled campaign production fee", "expenses"],
        renderCell: (params) => params.value ? <Chip label={params.value.charAt(0).toUpperCase() + params.value.slice(1)} variant="outlined" color="success" size="small" /> : null,
    };
    const columnPurchaseOrder: GridColDef = { field: 'purchaseOrder', headerName: 'PO Number', width: 150, aggregable: false, editable: true };
    const columnInvoiceNotes: GridColDef = { field: 'invoiceNotes', type: 'string', headerName: 'Invoice Notes', width: 150, aggregable: false, editable: true, renderEditCell: (params) => { return <TextAreaCell {...params} /> } };
    const columnUniportContactEmail: GridColDef = { field: 'uniportEmail', headerName: 'Uniport Contact Email', aggregable: false, width: 150, editable: true, preProcessEditCellProps: validateEmail, renderEditCell: CellWithValidation };
    const columnSalesLeadId: GridColDef = {
        field: 'salesLeadId', headerName: 'Sales Lead', aggregable: false, width: 150, editable: true, type: 'singleSelect',
        valueOptions: [/*{ value: '', label: 'None' },*/ ...salesTeam.map((salesManager) => { return { value: salesManager.id!, label: salesManager.email! } })],
        renderCell: (params: { value?: string }) => params.value ? <>{salesTeam.find((salesManager) => salesManager.id === params.value)?.email}</> : null,
    };
    const columnContractFile: GridColDef = {
        field: 'contractFile', headerName: 'Contract File', type: 'string', editable: true, aggregable: false, width: 150,
        renderCell: (params) => params.value ? <a className="text-decoration-none" href={params.value.url} rel="noreferrer" target="_blank">{params.value.fileName}</a> : null,
        renderEditCell: (params: GridRenderEditCellParams) => (
            <FileUpload params={params} existingFile={params.value} /> // submitFileForm={(fieldValues) => console.log(fieldValues)}
        ),
    };
    const columnTalentPaymentSent: GridColDef = { field: 'talentPaymentSent', headerName: 'Payment Sent', type: 'boolean', aggregable: false, width: 150 };
    const columnTalentPaymentSentDate: GridColDef = { field: 'talentPaymentSentDate', headerName: 'Payment Sent Date', type: 'date', aggregable: false, width: 150, valueGetter: (params) => params.value ? params.value.toDate() : null };
    const columnInvoiceLink: GridColDef = { field: 'invoiceLink', headerName: 'Invoice Link', type: 'string', aggregable: false, width: 150, editable: false, renderCell: (params) => params.value ? <a className="text-decoration-none" href={params.value} rel="noreferrer" target="_blank">{params.value}</a> : null };
    const columnActions: GridColDef = {
        field: 'actions', headerName: 'Actions', type: 'actions', width: 80, aggregable: false,
        // TJN TO-DO MAYBE: UPDATE TO official ACTIONS APPROACH...
        renderCell: (params) => {
            const booker = bookers?.find((booker) => booker.id === params.row.bookerId);
            const client: Client | undefined = clients?.find((client) => client.id === booker?.clientId || client.id === params.row.clientId);
            const campaign: Campaign | undefined = campaigns.find((campaign) => campaign.id === params.row.campaignId);
            const curTal = talent?.find((tal) => tal.id === params.row.talentId);

            return <><BookingActionButton disabled={!user?.roles?.admin && !params.row.managerIds?.includes(user?.id)} booking={params.row} booker={booker} client={client} campaign={campaign} talent={curTal} updateDocField={updateDocField} updateDoc={updateDoc} /></>
        }

    };

    ////////////////////////////////
    // Columns Order
    //////////////////////////////// 
    return [
        columnId,
        columnAirtableId,
        columnTalentName,
        ...(user?.roles?.manager || user?.roles?.admin ? [columnManagerIds] : []),
        ...(user?.roles?.manager || user?.roles?.admin ? [columnClient] : []),
        ...(user?.roles?.manager || user?.roles?.admin ? [columnBooker] : []),
        columnCampaign,
        columnDocMeta,
        ...(user?.roles?.manager || user?.roles?.admin ? [columnCreatedByManager] : []),
        columnMonthDue2,
        columnCurrency,
        ...(user?.roles?.manager || user?.roles?.admin ? [columnPrice] : []), // Toggle off for large datasets?
        columnOverrideMicroRevenue,
        columnExternalCommission, // Toggle off for large datasets?
        ...(user?.roles?.admin || user?.roles?.admin ? [columnOverrideManagerCommission] : []),
        ...(user?.roles?.manager || user?.roles?.admin ? [columnOverrideRemoveCommissions] : []),
        ...(user?.roles?.manager || user?.roles?.admin ? [columnAgentCommission] : []), // Toggle off for large datasets?
        columnStatus,
        columnVideoLink,
        columnBoostCode,
        ...(user?.roles?.manager || user?.roles?.admin ? [columnBookingType] : []),
        ...(user?.roles?.manager || user?.roles?.admin ? [columnPurchaseOrder] : []),
        ...(user?.roles?.manager || user?.roles?.admin ? [columnInvoiceNotes] : []),
        ...(user?.roles?.manager || user?.roles?.admin ? [columnUniportContactEmail] : []),
        ...(user?.roles?.salesTeam || user?.roles?.admin ? [columnSalesLeadId] : []),
        columnContractFile,
        columnTalentPaymentSent,
        columnTalentPaymentSentDate,
        ...(user?.roles?.manager || user?.roles?.admin ? [columnInvoiceLink] : []),
        columnActions,
    ] as GridColDef[];
}

/////////////////////////
// HELPER FUNCTIONS
/////////////////////////
// selectOption[]
function createSingleSelectFilterOperators(optionsArr: any[], labelParam: string) {
    return [
        {
            'label': 'is',
            'value': 'is',
            // https://mui.com/x/react-data-grid/filtering/#create-a-custom-operator
            getApplyFilterFn(filterItem, column) {
                // console.log(`filterItem`, filterItem);

                if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                    return null;
                }
                return (params): boolean => {
                    return params?.value?.includes(filterItem.value)
                };
            },
            // value={{ value: item.value, label: managers.find((manager) => manager.id === item.value)?.email || '' }}
            InputComponent: (props: GridFilterInputValueProps) => TypeaheadSingleSelect({ ...props, optionsArr, labelParam }),
        },
        {
            'label': 'is not',
            'value': 'isNot',
            getApplyFilterFn(filterItem, column) {
                // console.log(`filterItem`, filterItem);

                if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                    return null;
                }
                return (params): boolean => {
                    return !params?.value?.includes(filterItem.value)
                };
            },
            InputComponent: (props: GridFilterInputValueProps) => TypeaheadSingleSelect({ ...props, optionsArr: optionsArr, labelParam })
        },
        {
            'label': 'is any of',
            'value': 'isAnyOf',
            getApplyFilterFn(filterItem, column) {
                if (!filterItem.field || !filterItem.value?.length || !filterItem.operator) {
                    return null;
                }
                return (params): boolean => {
                    if (typeof params.value === 'object') {
                        return params?.value?.some((i: any) => filterItem.value.find((val: SelectOption) => val.value === i));
                    } else {
                        return filterItem.value.find((val: any) => val.value === params.value);
                    }
                };
            },
            InputComponent: (props: GridFilterInputValueProps) => TypeaheadMultiSelect({ ...props, optionsArr: optionsArr, labelParam }) // labelParam: 'email'
        },
        {
            'label': 'is not any of',
            'value': 'isNotAnyOf',
            getApplyFilterFn(filterItem, column) {
                if (!filterItem.field || !filterItem.value?.length || !filterItem.operator) {
                    return null;
                }
                return (params): boolean => {
                    if (typeof params.value === 'object') {
                        return !params?.value?.some((i: any) => filterItem.value.find((val: SelectOption) => val.value === i));
                    } else {
                        // return !filterItem.value.includes(params.value);
                        return !filterItem.value.find((val: any) => val.value === params.value);
                    }
                }
            },
            InputComponent: (props: GridFilterInputValueProps) => TypeaheadMultiSelect({ ...props, optionsArr: optionsArr, labelParam }) // labelParam: 'email'
        }
    ] as GridFilterOperator[]; // <any, any, any>[]
}

function getUniqueObjectsByKey(data: any, key: string) {
    // data = data.filter((dat: any) => dat[key]);

    if (data) {
        return Object.values(
            data.reduce((acc: any, cv: any) => {
                // if (!acc[cv.name.toLowerCase()]) acc[cv.name.toLowerCase()] = cv;
                // return acc;

                const k = cv[key]?.toLowerCase().trim();
                if (!acc[k]) {
                    acc[k] = cv
                }
                return acc;
            }, {})
        );
    } else {
        return [];
    }
}

function sortSelectOptions(a: SelectOption | string, b: SelectOption | string) {
    if (typeof a === 'string' && typeof b === 'string') {
        return a.toLowerCase() > b.toLowerCase() ? 1 : -1;
    } else if (typeof a !== 'string' && typeof b !== 'string') {
        return a.label?.toLowerCase() > b.label?.toLowerCase() ? 1 : -1;
    } else {
        // never happens
        return 1;
    }
}

const currencyFormat = new Intl.NumberFormat("en", {
    minimumFractionDigits: 2,
});

export function agentCommissionValueGetter(params: any) {
    if (typeof params.row.overrideManagerCommission === 'number') {
        return params.row.overrideManagerCommission;
    } else if (params.row.overrideRemoveCommissions || params.row.overrideMicroRevenue) {
        return 0;
    } else {
        if (params.row.approvalDate && dayjs(grailUtil.timestampOrDateToDate(params.row.approvalDate)).isBefore(dayjs('2023-10-01'))) {
            return (params.row.price - (params.row.price * 0.015)) * 0.1;
        }
        return (params.row.price - (params.row.price * 0.02375)) * 0.1;
    }
}

function createIsNotAnyFilterOperator(optionsArr: any[]) {
    return {
        'label': 'is not any of',
        'value': 'isNotAnyOf',
        getApplyFilterFn(filterItem, column) {
            if (!filterItem.field || !filterItem.value?.length || !filterItem.operator) {
                return null;
            }
            return (params): boolean => {
                return !filterItem.value.includes(params.value);
            }
        },
        InputComponent: (props: GridFilterInputValueProps) => TypeaheadMultiSelect({ ...props, optionsArr: optionsArr, labelParam: null })
    } as GridFilterOperator;
}

// // NOTE - This is the most memory intensive of the aggregation function subcomponents, if we need to revisit...
// export function externalCommissionValueGetter(params: any, talent: Talent[]) {
//     return params.row.talentId ? talent?.find((tal) => tal.id === params.row.talentId)?.externalCommission : 0;
//     // return 0;
// }