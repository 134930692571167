import { useEffect } from 'react';
import { updateDocField, updateDoc } from '../services/firebase';
import { Booking, Campaign, Client, Talent, User } from '../shared/types'; // User, Talent
import grailUtil from '../services/grailUtil';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';

import accounting from 'accounting';

// Components
import { DataGridPremium, GridLogicOperator, GridAggregationFunction, FilterColumnsArgs } from '@mui/x-data-grid-premium';
import Tooltip from '@mui/material/Tooltip';
import CheckBoxOutlineBlankOutlined from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlined from '@mui/icons-material/CheckBoxOutlined';
import { generateColumns, agentCommissionValueGetter } from '../components/grid/Columns'; // externalCommissionValueGetter
import NewCustomToolbar from './grid/NewCustomToolbar';

interface BookingsGridProps {
    apiRef?: React.MutableRefObject<GridApiPremium>,
    bookers: User[],
    bookings: Booking[],
    campaigns: Campaign[],
    clients: Client[],
    managers: User[],
    salesTeam: User[],
    talent: Talent[],
    user: User,
    searchMode?: boolean,
    allowAggregation?: boolean,
    loading?: boolean,
}

export default function BookingsGrid(props: BookingsGridProps) {
    const { apiRef, bookers, bookings, campaigns, clients, managers, salesTeam, searchMode, talent, user, allowAggregation, loading } = props;

    // Little efficiency boost...
    const relevantCampaignIds = bookings.map((booking) => booking.campaignId);
    const relevantCampaigns = campaigns.filter((campaign) => relevantCampaignIds.includes(campaign.id));

    // HUGE efficiency boost; write external commission here instead of in valueGetter... way more performant, even with lots of rows 
    const bookingsToUse = bookings.map((booking) => {
        return { ...booking, externalCommission: booking.talentId ? talent?.find((tal) => tal.id === booking.talentId)?.externalCommission : 0 }
    })

    useEffect(() => {
        document?.body?.classList.add('overflow-x-hidden');
    }, []);

    const columns = generateColumns({ apiRef, bookers, campaigns: relevantCampaigns, clients, managers, salesTeam, talent, user: user || {} });

    return (
        <div style={{ display: 'flex', height: 'calc(100vh - 7rem)', width: '100%' }}>
            <DataGridPremium
                loading={loading}
                throttleRowsMs={200}
                showCellVerticalBorder
                density="compact"
                slots={{
                    toolbar: NewCustomToolbar,
                    booleanCellTrueIcon: CheckBoxOutlined,
                    booleanCellFalseIcon: CheckBoxOutlineBlankOutlined,
                }}
                slotProps={{
                    filterPanel: {
                        filterFormProps: {
                            filterColumns,
                        },
                        // Don't allow the first filter to be the groupable one either...
                        getColumnForNewFilter: ({ columns }) => columns.filter((col) => col.field !== '__row_group_by_columns_group__')[0].field

                    }
                }}
                hideFooter
                // columnBuffer={100}
                apiRef={apiRef} rows={bookingsToUse} columns={columns}
                isCellEditable={(params) => {
                    const userHasPermission = params.row.managerIds?.includes(user?.id) || user?.roles?.admin;
                    if (params.field === 'price') {
                        return userHasPermission && params.row.status !== 'approved';
                    } else if (params.field === 'overrideMicroRevenue' || params.field === 'overrideManagerCommission') {
                        return params.row.status !== 'approved';
                    } else if (params.field === "salesLeadId") {
                        return user?.roles?.salesTeam;
                    } else {
                        return userHasPermission;
                    }
                }}

                processRowUpdate={(newRow, oldRow) => {
                    const changedFields = grailUtil.findChangedObjectKeys(oldRow, newRow);
                    if (Object.keys(changedFields).length === 1) {
                        const field = Object.keys(changedFields)[0];
                        const value = newRow[field];
                        const docId = newRow.id.toString();

                        if (field === 'monthDue2') {
                            updateDocField("bookings", newRow.id.toString(), field, grailUtil.monthDueToTimestamp(value));
                        } else if (field === 'salesLeadId') {
                            const airtableSalesLeadId = managers?.find((manager) => manager.id === value)?.managerAirtableId;
                            updateDoc("bookings", docId, { salesLeadId: value, airtableSalesLeadId: airtableSalesLeadId || "" }, "salesLeadId");
                        } else {
                            updateDocField("bookings", docId, field, value);
                            // Automatically update status to posted if valid video link provided...
                            const status = apiRef?.current.getRow(docId || '').status;
                            if (value && field === 'videoLink' && (status === 'confirmed' || status === 'draft')) {
                                updateDocField("bookings", docId, "status", "posted");
                            }
                        }

                    } else if (Object.keys(changedFields).length > 1) {
                        console.log(`changedFields`, changedFields);
                        alert("ERROR!");
                    }

                    return newRow; // must return to actually update row internally
                }}

                // rowsPerPageOptions={[100, 500, 1000]}
                // pagination
                initialState={{
                    columns: {
                        columnVisibilityModel: user?.managerBookingColumns || {
                            id: false, // Grail Platform ID
                            docMeta: false, // Created Date
                            overrideMicroRevenue: false, // Creator Commission Override
                            // overrideRemoveCommissions: false,
                            overrideManagerCommission: false, // Manager Commission Override
                            externalCommission: false, // External Commission
                            // bookingType: false,
                            createdByManager: false, // Created by Grail Team
                            boostCode: false, // Boost Code
                            salesLeadId: false, // Sales Lead
                        },
                    },
                    sorting: {
                        // sortModel: [{ field: 'monthDue', sort: 'desc' }], // , { field: 'docMeta', sort: 'desc' }
                        sortModel: user?.managerBookingSorting || [{ field: '__row_group_by_columns_group__', sort: 'desc' }]
                    },
                    filter: {
                        // Don't filter in single-booking mode...
                        filterModel: searchMode ? {
                            items: [],
                            logicOperator: GridLogicOperator.And,
                        } :
                            // otherwise use the manager's filters or a default filter if they don't have one
                            user?.managerBookingFilters || {
                                items: [{ id: 1, field: 'managerIds', operator: 'is', value: user?.id || '' }],
                                logicOperator: GridLogicOperator.And,
                            },
                    },
                    rowGrouping: {
                        model: ['monthDue2'],
                    },
                    aggregation: allowAggregation ? {
                        model: {
                            'price': 'sumByCurrency',
                            'agentCommission': 'sumByCurrency',
                            'externalCommission': 'sumByCurrency',
                        },
                    } : undefined,
                    pinnedColumns: user?.managerBookingPinnedColumns || {},
                }}
                aggregationFunctions={{
                    sumByCurrency: sumByCurrency(talent),
                }}
                // aggregationModel={{
                //     'price': 'sumByCurrency',
                //     'agentCommission': 'sumByCurrency',
                //     'externalCommission': 'sumByCurrency',
                // }}
                sx={{
                    border: 'none',
                    '& .MuiDataGrid-toolbarContainer': {
                        padding: '1rem 0',
                    },
                    '& .MuiButton-textPrimary': {
                        height: '2rem',
                        borderRadius: '1rem',
                        padding: '0 0.75rem',
                        color: '#000',
                        border: '1px solid #62edff',
                        margin: '0.25rem 0.5rem 0.25rem 0',
                        '&:hover': {
                            'backgroundColor': 'rgba(98, 237, 255, 0.3)'
                        }
                    }
                }}
                defaultGroupingExpansionDepth={1}
                // Use JSON.parse/JSON.stringify to handle undefined values...
                onColumnVisibilityModelChange={(newColumnModel) => user?.id ? updateDocField("users", user?.id, "managerBookingColumns", JSON.parse(JSON.stringify(newColumnModel))) : console.log("")}
                onFilterModelChange={(newFilterModel) => user?.id ? updateDocField("users", user?.id, "managerBookingFilters", JSON.parse(JSON.stringify(newFilterModel))) : console.log("")}
                onSortModelChange={(newSortModel) => user?.id ? updateDocField("users", user?.id, "managerBookingSorting", JSON.parse(JSON.stringify(newSortModel))) : console.log("")} //console.log('newSortModel', newSortModel)
                onPinnedColumnsChange={(newPinnedColumns) => user?.id ? updateDocField("users", user?.id, "managerBookingPinnedColumns", JSON.parse(JSON.stringify(newPinnedColumns))) : console.log("")}
                onProcessRowUpdateError={(error) => console.log(`error`, error)}
            // onRowGroupingModelChange={(newGroupingModel) => console.log(`groupingModel`, newGroupingModel)}
            />
        </div>
    );
}

/////////////////////////
// HELPER FUNCTIONS
/////////////////////////

// Don't allow filtering on the second "grouping" version of Month Due
// https://mui.com/x/react-data-grid/filtering/multi-filters/#one-filter-per-column
const filterColumns = ({ field, columns, currentFilters }: FilterColumnsArgs) => {
    // console.log(`columns`, columns);
    // console.log(`currentFilters`, currentFilters);

    return columns
        .filter((colDef) => colDef.field !== "__row_group_by_columns_group__")
        .map((column) => column.field);
};

function sumByCurrency(talent: Talent[]): GridAggregationFunction<any, any | null> {
    return {
        getCellValue: ({ row }) => {
            // 1. Don't send more data in here than you need to, as it gets replicated across tons of rows...
            // 2. Costs less memory to send only the source variables to getCellValue and calculate the result values in `apply`
            const miniRow = {
                currency: row.currency,
                price: row.price,
                talentId: row.talentId,
                overrideManagerCommission: row.overrideManagerCommission,
                overrideRemoveCommissions: row.overrideRemoveCommissions,
                overrideMicroRevenue: row.overrideMicroRevenue,
                approvalDate: row.approvalDate,
                // agentCommission: agentCommissionValueGetter({ row: row }),
                // externalCommission: externalCommissionValueGetter({ row: row }, talent)
                externalCommission: row.externalCommission
            }
            return miniRow;
        },

        apply: (params) => {
            if (params.values.length === 0) {
                return null;
            }

            let USD = 0;
            let GBP = 0;
            let unknown = 0;

            // Just do this once, not on every loop pass
            const externalCommissionTalent = talent.filter((tal) => tal.externalCommission);

            // For the given field type, get the relevant field value, and sum by each currency...
            for (const miniRow of params.values) {
                let fieldValue = 0;
                if (params.field === 'price' && miniRow.price) {
                    fieldValue = parseFloat(miniRow.price);
                } else if (params.field === 'agentCommission') {
                    fieldValue = agentCommissionValueGetter({ row: miniRow }) || 0;
                } else if (params.field === 'externalCommission') {
                    // This is almost the same as externalCommissionValueGetter...
                    // But we're using the shorter externalCommissionTalent array to improve performance 
                    fieldValue = externalCommissionTalent.find((tal) => tal.id === miniRow.talentId)?.externalCommission || 0;
                }

                if (fieldValue) {
                    if (miniRow.currency === 'USD') {
                        USD += fieldValue;
                    } else if (miniRow.currency === 'GBP') {
                        GBP += fieldValue;
                    } else {
                        unknown += fieldValue;
                    }
                }
            }

            return {
                USD: USD,
                GBP: GBP,
                unknown: unknown,
            }
        },
        // Keep your value formatter separate... seems to help with memory
        // valueFormatter: (params) => params.value,
        valueFormatter: (params) => {
            const USD = params.value?.USD;
            const GBP = params.value?.GBP;
            const unknown = params.value?.unknown;

            let unknownBlock;

            if (unknown) {
                unknownBlock = <Tooltip title={`Total of bookings without currency: ${accounting.formatMoney(unknown, '', 2, ',')}`}><span>&nbsp;*</span></Tooltip>
            }

            return (
                <div className="font-14">
                    {USD ?
                        <div className="flex justify-content-end">
                            <div>$</div>
                            <div className="flex justify-content-between">
                                {accounting.formatMoney(USD, '', 2, ',')}
                                {unknownBlock ? unknownBlock : null}
                            </div>
                        </div>
                        : null
                    }
                    {GBP ?
                        <div className="flex justify-content-end">
                            <div>£</div>
                            <div className="flex justify-content-between">
                                {accounting.formatMoney(GBP, '', 2, ',')}
                                {unknownBlock ? unknownBlock : null}
                            </div>
                        </div>
                        : null
                    }
                </div>
            );
        },
        label: 'money',
    };
}