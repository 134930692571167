import { Dispatch, SetStateAction, useState } from "react";
import grailUtil from '../../services/grailUtil';

//
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import SettingsIcon from '@mui/icons-material/Settings';
import Box from '@mui/material/Box';

import ModalStyle from '../../theme/mui-styles/modal';
import DataLoading from "../DataLoading";
import ManagerSingleSelect from "../ManagerSingleSelect";
import { User } from "../../shared/types";

interface BookingQueryOptionsProps {
    managers: User[],
    managerId: string,
    setManagerId: Dispatch<SetStateAction<string>>,
    fromMonth: string,
    toMonth: string,
    setFromMonth: Dispatch<SetStateAction<string>>,
    setToMonth: Dispatch<SetStateAction<string>>,
    [key: string]: unknown
}

export default function BookingQueryOptions(props: BookingQueryOptionsProps) {
    const { managers, managerId, setManagerId, fromMonth, toMonth, setFromMonth, setToMonth } = props; // setJustChanged
    const [openModalId, setOpenModalId] = useState<string>('');
    const monthOptions = grailUtil.createMonthOptions();

    let managerOptions = managers.sort((a, b) => (a.email || '').localeCompare(b.email || ''));
    managerOptions = [{ id: '', email: 'All Managers (Slow)' }, ...managerOptions];

    return (
        <div className="px-100 flex flex-wrap align-items-center justify-content-between" style={{ position: 'absolute', top: '1rem', right: 0, zIndex: 2 }}>
            <div className="flex align-items-center justify-content-center">
                <DataLoading />
                <div className="ml-50">
                    <IconButton size="small" onClick={() => setOpenModalId('settings')}>
                        <SettingsIcon />
                    </IconButton>
                </div>
            </div>

            <Modal open={openModalId === 'settings'} onClose={() => setOpenModalId('')}>
                <Box sx={ModalStyle}>

                    <div className="form-group">
                        <label>Manager:</label>
                        <ManagerSingleSelect
                            managers={managerOptions}
                            value={managerId}
                            onChange={(e, value) => {
                                console.log(`setManagerId`, value);
                                setManagerId(value || '');
                            }}
                        />
                    </div>

                    <div className="flex mt-50">
                        <div className="form-group flex-1-0-auto mr-50">
                            <label>From Month Due:</label>
                            <select value={fromMonth} onChange={(e) => setFromMonth(e.target.value)}>
                                {monthOptions.map((month) =>
                                    <option key={month} value={month}>{month}</option>
                                )}
                            </select>
                        </div>

                        <div className="form-group flex-1-0-auto">
                            <label>To Month Due:</label>
                            <select onChange={(e) => setToMonth(e.target.value)}>
                                {monthOptions.map((month, idx) =>
                                    <option key={month} value={toMonth}
                                        // only allow selection of months newer than the fromMonth
                                        disabled={idx > monthOptions.indexOf(fromMonth)}
                                    >
                                        {month}
                                    </option>
                                )}
                            </select>
                        </div>
                    </div>

                    <div className="mt-100 font-12"><strong>NOTE:</strong> setting to "All Managers", or using large date ranges, pulls thousands of records and may be slow.</div>
                </Box>
            </Modal>
        </div>
    );
}