import { GridFilterInputValueProps, } from '@mui/x-data-grid-premium';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

export function TypeaheadSingleSelect(props: GridFilterInputValueProps & { optionsArr: any[], labelParam: string }) {
    const { item, applyValue, optionsArr, labelParam } = props; // focusElementRef
    const options = optionsArr.map((opt) => ({ value: opt.id || '', label: (opt[labelParam] || '').toLowerCase() as string })).sort(sortSelectOptions);

    function createLabel() {
        return optionsArr.find((opt) => opt.id === item.value)?.[labelParam] || '';
    }

    return (
        <Autocomplete
            isOptionEqualToValue={(option, value) => option.value === value.value}
            value={item.value ? { value: item.value || '', label: createLabel() } : null}
            // value={{ value: item.value, label: managers.find((manager) => manager.id === item.value)?.email || '' }}
            onChange={(event, value) => { applyValue({ ...item, value: value?.value }) }}
            id="combo-box-demo"
            options={options} // options={options.sort(sortSelectOptions)}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Value" variant="standard" />}
        />
    );
}

export function TypeaheadMultiSelect(props: GridFilterInputValueProps & { optionsArr: any[], labelParam: string | null }) { // multiple?: boolean
    const { item, applyValue, optionsArr, labelParam } = props; // focusElementRef, multiple

    // console.log(`item`, item);

    console.log('labelParam', labelParam);

    let options;

    if (labelParam) {
        options = (optionsArr).map((opt) => ({ value: opt.id || '', label: (opt[labelParam] || '').toLowerCase() as string }));
    } else {
        options = optionsArr;
    }

    console.log(`value from options`, options.find((opt) => opt.value === 'd3X2hL81cdSG9lsOYP5q'));

    return (
        <Autocomplete
            multiple
            // isOptionEqualToValue={(option, value) => value.value.find((val) => val.value === option.value)}
            value={item.value || []}
            onChange={(event, value) => { console.log(`value fron change`, value); applyValue({ ...item, value: value }) }}
            id="combo-box-demo"
            options={options.sort(sortSelectOptions)}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Value" variant="standard" />}
        />
    );
}

export interface SelectOption {
    value: string;
    label: string;
}

function sortSelectOptions(a: SelectOption | string, b: SelectOption | string) {
    if (typeof a === 'string' && typeof b === 'string') {
        return a.toLowerCase() > b.toLowerCase() ? 1 : -1;
    } else if (typeof a !== 'string' && typeof b !== 'string') {
        return a.label?.toLowerCase() > b.label?.toLowerCase() ? 1 : -1;
    } else {
        // never happens
        return 1;
    }
}