import { useState, useContext } from 'react';
import { UserContext, useFreshManagers, useBookingsStream, useRelevantDocs } from '../services/firebase';
import grailUtil from '../services/grailUtil';

// Components
import Layout from '../components/Layout';
import { useGridApiRef } from '@mui/x-data-grid-premium';

// Styles
import './Bookings.scss';
import BookingsGrid from '../components/BookingsGrid';
// import BookingsQueryOptions from '../components/grid/BookingsQueryOptionsDeprecated';
// import { useQueryClient } from '@tanstack/react-query';
import { Campaign, Client, Talent, User } from '../shared/types';
import BookingQueryOptions from '../components/grid/BookingQueryOptions';

export default function Bookings() {
    const user = useContext(UserContext);
    const apiRef = useGridApiRef();
    const [managerId, setManagerId] = useState(user?.id || '');
    const [fromMonth, setFromMonth] = useState(grailUtil.createMonthOptions(3, 0).at(-1)!); // default 3 months ago
    const [toMonth, setToMonth] = useState(grailUtil.createMonthOptions(0, 12).at(0)!); // default 12 months in the future

    // 1. Get bookings based on settings (bookings array resets to empty if you change the params, then loads the data)
    const { bookings, loading } = useBookingsStream({ managerId, fromMonth, toMonth });
    // Individual Manager Test Case using raffa@ ... can also try tancredi@
    // const { bookings, loading } = useBookingsStream({ managerId: 'Gs6TQVEvmpPBPZbIwUVb3JbSONo2', apiRef, limitToManager, limitToLast3Months });
    const shouldFetchSecondaryData = bookings.length > 0;

    // 2. Get Unique Ids Based on Bookings Array
    const campaignIds = [...new Set(bookings.filter((booking) => booking.campaignId).map((booking) => booking.campaignId!))];
    const bookerIds = [...new Set(bookings.filter((booking) => booking.bookerId).map((booking) => booking.bookerId!))];
    const talentIds = [...new Set(bookings.filter((booking) => booking.talentId).map((booking) => booking.talentId!))];
    const clientIds = [...new Set(bookings.filter((booking) => booking.clientId).map((booking) => booking.clientId!))];

    // 3. If we have bookings, pull the matching data
    const bookers = useRelevantDocs<User>({ collectionName: 'users', docIds: bookerIds, enabled: shouldFetchSecondaryData }).data;
    const talent = useRelevantDocs<Talent>({ collectionName: 'talent', docIds: talentIds, enabled: !!bookers?.length && shouldFetchSecondaryData }).data;
    const clients = useRelevantDocs<Client>({ collectionName: 'clients', docIds: clientIds, enabled: !!talent?.length && shouldFetchSecondaryData }).data;
    const campaigns = useRelevantDocs<Campaign>({ collectionName: 'campaigns', docIds: campaignIds, enabled: !!clients?.length && shouldFetchSecondaryData }).data;

    // 3. REACT QUERY for managers/sales because we (maybe) need them all
    const managersQuery = useFreshManagers({ enabled: shouldFetchSecondaryData });
    const managers = managersQuery.flatData;
    const salesTeam = (managers || [])?.filter((manager) => manager.roles?.salesTeam);


    return (
        <div className="BookingsPage">
            <Layout gray={true}>
                <div style={{ position: 'relative' }}>
                    {/* <BookingQueryOptionsDeprecated
                        isManager={user?.roles?.manager}
                        limitToManager={limitToManager}
                        setLimitToManager={setLimitToManager}
                        limitToLast3Months={limitToLast3Months}
                        setLimitToLast3Months={setLimitToLast3Months}
                    /> */}

                    {(user?.roles?.manager || user?.roles?.admin) &&
                        <BookingQueryOptions
                            managers={managers}
                            managerId={managerId}
                            fromMonth={fromMonth}
                            toMonth={toMonth}
                            setManagerId={setManagerId}
                            setFromMonth={setFromMonth}
                            setToMonth={setToMonth}
                        />
                    }

                    <BookingsGrid
                        apiRef={apiRef}
                        bookers={bookers || []}
                        bookings={bookings}
                        campaigns={campaigns || []}
                        clients={clients || []}
                        managers={managers}
                        salesTeam={salesTeam}
                        talent={talent || []}
                        user={user || {}}
                        allowAggregation={managerId !== ''} // only allow aggregation if a manager is selected
                        loading={loading}
                    />

                </div>
            </Layout>
        </div>
    );
}