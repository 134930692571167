
import { useEffect, useContext, useReducer } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { FieldValues, useForm } from "react-hook-form";

// Grail Services & Types
import { UserContext, useExchangeRate, useFreshLists, useFreshBookers, useFreshClients, useFreshTalent, useFreshCountries, useFreshTags, getCampaignsByBookerId, generateNewDocId, newCreateOrUpdateCampaign, acceptPaymentTerms, createClient, createUser, useFreshCities } from "../services/firebase";
import { analyticsService } from "../services/analytics";
import grailUtil from "../services/grailUtil";
import { Talent, User, Booking, TalFilter, Success, Failure, Campaign, List, SelectedTalent, Currency } from "../shared/types";

// Components
import Layout from "../components/Layout";
import TalentFilter, { defaultFilter } from "../components/TalentFilter";
import BookerSelect from "../components/BookerSelect";
import DataLoading from "../components/DataLoading";
import BookingSummary from "../components/BookingSummary";
import BookingNavigation from "../components/BookingNavigation";
import BookingForm from "../components/BookingForm";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import modalStyle from '../theme/mui-styles/modal';

// Styles
import './NewBooking.scss';

export default function NewBooking() {
    const { listId } = useParams<{ listId: string }>();
    const authenticatedUser = useContext(UserContext);
    const queryClient = useQueryClient();
    const bookersQuery = useFreshBookers();
    const bookers = bookersQuery.flatData;
    const clientsQuery = useFreshClients();
    const clients = clientsQuery.flatData;
    const talentQuery = useFreshTalent({ requestByManager: authenticatedUser?.roles?.manager || authenticatedUser?.roles?.admin, includeDeleted: false });
    const rawTalent = talentQuery.flatData;

    const reactHookForm = useForm();

    // NEW
    const countriesQuery = useFreshCountries();
    const countryList = countriesQuery.flatData.filter((country) => country.liveOnAirtable).map((country) => country.label).sort();
    const tagsQuery = useFreshTags();
    const tagList = tagsQuery.flatData.map((tagDoc) => tagDoc.tag).sort();
    const cityQuery = useFreshCities();
    const cityList = cityQuery.flatData.map((cityDoc) => cityDoc.city).sort();

    const poundsToDollars = useExchangeRate();
    const listsQuery = useFreshLists({ listId });
    const listData = listsQuery.flatData;

    // LOCAL STATE
    const [state, dispatch] = useReducer(newBookingStateReducer, getInitialState({ user: authenticatedUser, listId }));
    const { talent, booker, totalPrice, totalTiktokFollowers, totalInstagramFollowers, step, selectedTalent, showMobileSummary, filter, lists, submissionStatus, creedModalOpen } = state;

    // EFFECT: calculateTalentWithPrices
    useEffect(() => {
        // console.log("EFFECT: calculateTalentWithPrices");

        let listTalent: SelectedTalent = {};
        if (lists.length && listId) {
            listTalent = (lists.find((list) => list.id === listId) || {}).talent || {};
        }

        const talentWithPrice = calculateTalentWithPrices(rawTalent, poundsToDollars, filter, listTalent);
        dispatch({ type: 'setStatePartial', payload: { talent: talentWithPrice } });
    }, [rawTalent, poundsToDollars, filter, lists, listId]);

    // EFFECT: Lists
    useEffect(() => {
        dispatch({ type: 'setLists', lists: listData, listId });
        // if (listId && listData[0]?.platform) {
        //     dispatch({ type: 'updateFilter', filterPartial: { campaignPlatform: listData[0].platform } })
        // }

        if (listId && listData[0]) {
            const singleList = listData[0];

            const filterPartial: Partial<TalFilter> = {
                ...(singleList.platform && { campaignPlatform: singleList.platform, lockCampaignPlatform: true }),
                ...(singleList.campaignType && { campaignType: singleList.campaignType, lockCampaignType: true }),
                ...(singleList.currency && { currency: singleList.currency, lockCurrencyFromList: true })
            };

            dispatch({
                type: 'updateFilter', filterPartial
            })
        }
    }, [listId, listData]);

    // EFFECT: campaigns
    useEffect(() => {
        // console.log("Effect: campaigns");
        if (booker?.id) {
            getCampaignsByBookerId(booker.id).then((campaigns) => dispatch({ type: 'setStatePartial', payload: { campaigns } }));
        } else {
            dispatch({ type: 'setStatePartial', payload: { campaigns: [] } })
        }
    }, [booker?.id]);

    async function submitBooking(formData: FieldValues) {
        dispatch({ type: 'navigateToStep', step: 'confirmation' });

        const campaignAndNewBookings = await buildCampaignAndBookings(formData, authenticatedUser, state, talent);
        const { campaign, bookings } = campaignAndNewBookings;

        const campaignRes = await newCreateOrUpdateCampaign(campaign, bookings, formData.file);
        if (formData.paymentTerms?.termsAccepted) {
            try {
                console.log(`terms accepted, attempting to record`);
                await acceptPaymentTerms({ firstName: booker?.firstName, lastName: booker?.lastName, userId: booker?.id, clientId: booker?.clientId });
            } catch (e) {
                console.log(e);
            }
        }

        dispatch({ type: 'setSubmissionStatus', status: campaignRes });
        console.log(`campaignRes`, campaignRes);
        analyticsService().logEvent(formData.campaign.id ? "Updated Campaign" : "Created Campaign", { ...campaign, bookings: JSON.stringify(campaign.bookings) });

        // await grailUtil.sleep(10000);
        // dispatch({ type: 'setSubmissionStatus', status: { success: false, error: 'Whoops' } })
    }

    async function selectBooker(booker?: User) {
        if ((booker && booker.id) || !booker) {
            dispatch({ type: 'setBooker', booker });
        } else {
            const res = await createBooker(booker, authenticatedUser);

            if (res.success) {
                console.log("NEW UID", res.result);
                queryClient.refetchQueries({ queryKey: ['bookers'] }); // If creating a new booker, refetch the booker query!
                dispatch({ type: 'setBooker', booker: { ...booker, id: res.result } });
            } else {
                alert(`${res.error} - ${booker.email} - Please contact Lyonel and Tim and copy and paste this message.`);
            }
        }
    }

    return (<>
        <Layout gray={true}>
            <div className="NewBookingPage">
                <div className="BookingStep1">
                    <Modal open={creedModalOpen} onClose={() => dispatch({ type: 'setStatePartial', payload: { creedModalOpen: false } })}>
                        <Box sx={{ ...modalStyle }}>
                            <div>
                                <h3>Creed Discount Reminder</h3>
                                <p>Remember to log the booking price for this deal as the "post-discount" rate (taking into account the 5% discount agreed on Creed deals).</p>
                                <button className='btn btn-primary' onClick={() => dispatch({ type: 'setStatePartial', payload: { creedModalOpen: false } })}>Okay!</button>
                            </div>
                        </Box>
                    </Modal>

                    <div className="Main">

                        {step === 'list' ? (
                            <div className="BookingTalentList">
                                {authenticatedUser?.roles?.manager ? (
                                    <div className="form-group mt-100">
                                        <label className="required">Select a Booker</label>
                                        {bookers.length && clients.length ?
                                            <BookerSelect
                                                value={booker}
                                                bookers={bookers}
                                                clients={clients}
                                                onBookerSelect={selectBooker}
                                                disabled={!!(Object.keys(selectedTalent || {}).length)}
                                            />
                                            : <DataLoading />}
                                    </div>
                                ) : null}

                                {authenticatedUser ? (
                                    <>
                                        <TalentFilter
                                            talent={talent}
                                            lists={lists}
                                            selectedTalent={selectedTalent}
                                            filter={filter}
                                            countryList={countryList}
                                            tagList={tagList}
                                            cityList={cityList}
                                            updateFilter={(filterPartial) => dispatch({ type: 'updateFilter', filterPartial })}
                                            handleAdd={(id, selectedTalentItem) => dispatch({ type: 'addTalent', id, selectedTalentItem })}
                                            handleRemove={(id) => dispatch({ type: 'removeTalent', id })}
                                        // showRecClicks={showRecClicks}
                                        // recs={recs}
                                        />
                                    </>
                                ) : null}

                            </div>
                        ) : null}

                        {step === 'form' ? (
                            <>
                                <BookingForm
                                    bookingState={state}
                                    reactHookForm={reactHookForm}
                                />
                            </>
                        ) : null}

                        {step === 'confirmation' ? (
                            <>
                                {submissionStatus === undefined ? (
                                    <div className="text-center mt-300 mb-300">
                                        <CircularProgress />
                                    </div>
                                ) : null}

                            </>
                        ) : null}

                        {submissionStatus?.success === true ? (<>
                            {/* authenticatedUser?.roles?.manager */}

                            {authenticatedUser?.roles?.manager ? (
                                <div className="text-center">
                                    <h2 className="mt-300 mb-100">All set!</h2>
                                    <div className="mb-100">Your booking is saved.</div>
                                    <div className="mt-100"><a href="/">Return to dashboard...</a></div>
                                    <div className="mt-300"><a href="/new-booking">Start another booking...</a></div>
                                </div>
                            ) : (
                                <div className="text-center">
                                    <h2 className="mt-300 mb-100">Thank You!</h2>
                                    <div className="mb-100">We'll be in touch soon!</div>
                                    <div className="mt-300"><a href="/">Start another booking...</a></div>
                                </div>
                            )}
                        </>
                        ) : null}

                        {submissionStatus?.success === false ? (
                            <div className="text-center mt-300 mb-300">An Error Occured. Please try again.</div>
                        ) : null}
                    </div>

                    <div className="Side">
                        {/* NOTE: recsLength and onRecsClick only for step #1... */}
                        <BookingSummary
                            talent={talent}
                            selectedTalent={selectedTalent}
                            userCurrency={filter.currency}
                            totalPrice={totalPrice}
                            totalTiktokFollowers={totalTiktokFollowers}
                            totalInstagramFollowers={totalInstagramFollowers}
                            handleRemove={(id) => dispatch({ type: 'removeTalent', id })}
                        // recsLength={recsLength}
                        // onRecsClick={ () => setShowRecClicks(showRecClicks + 1)}
                        />
                    </div>

                    {/* TJN TO-DO: Lists... can I consolidate this into the same one as above? */}
                    <div className={`MobileSummary${showMobileSummary ? '' : ' hidden'}`}>
                        <div className="MobileSummaryBody">
                            <BookingSummary
                                talent={talent}
                                selectedTalent={selectedTalent}
                                userCurrency={filter.currency}
                                totalPrice={totalPrice}
                                totalTiktokFollowers={totalTiktokFollowers}
                                totalInstagramFollowers={totalInstagramFollowers}
                                handleRemove={(id) => dispatch({ type: 'removeTalent', id })}
                            // recsLength={recsLength}
                            // onRecsClick={ () => setShowRecClicks(showRecClicks + 1)}
                            />
                        </div>
                    </div>

                    {/* TJN TO-DO: Lists -- Clean Up? */}
                    <form onSubmit={reactHookForm.handleSubmit(submitBooking)}>
                        {step !== 'confirmation' ? (
                            <BookingNavigation
                                bookingStep={step}
                                navigateToStep={(step: Step) => dispatch({ type: 'navigateToStep', step })}
                                showMobileSummary={showMobileSummary}
                                setShowMobileSummary={(show: boolean) => dispatch({ type: 'setStatePartial', payload: { showMobileSummary: show } })}
                                selectedTalent={selectedTalent}
                                booker={booker}
                                formSubmitDisabled={reactHookForm.formState.isSubmitting}
                            />
                        ) : null}
                    </form>
                </div>
            </div>
        </Layout>
    </>);
}

// type SelectedTalent = { [key: string]: number };
type Step = 'list' | 'form' | 'confirmation';

export type NewBookingState = {
    talent: Talent[],
    lists: List[],
    // countries: Country[],

    booker?: User,
    campaigns: Campaign[], // depends on selectedBooker
    //
    filter: TalFilter,
    //
    selectedTalent: SelectedTalent,
    totalTiktokFollowers: number, // depends on selectedTalent
    totalInstagramFollowers: number, // depends on selectedTalent,
    totalPrice: number, // depends on selectedTalent
    // 
    step: Step,
    submissionStatus?: Success | Failure,
    //
    showBrandAlert: boolean,
    creedModalOpen: boolean,
    creedModalShown: boolean,
    showMobileSummary: boolean,
}

type NewBookingStateAction =
    { type: 'setStatePartial', payload: Partial<NewBookingState> } |
    { type: 'navigateToStep', step: Step } |
    // { type: 'addTalent', id: string, bidPrice: number, isRec?: boolean } |
    { type: 'addTalent', id: string, selectedTalentItem: SelectedTalent["id"] } |
    { type: 'removeTalent', id: string } |
    { type: 'updateFilter', filterPartial: Partial<TalFilter> } |
    { type: 'setBooker', booker: User | undefined } |
    { type: 'setLists', lists: List[], listId: string | undefined } |
    { type: 'setSubmissionStatus', status: Success | Failure };


interface StateOptions {
    user?: User | undefined,
    // customList?: List,
    listId?: string,
    // listId?: string | undefined,
    // partialFilter?: Partial<TalFilter>,
}

// function getInitialState(user: User | undefined, listId: string | undefined) {
function getInitialState(stateOptions: StateOptions): NewBookingState {
    const { user, listId } = stateOptions;
    const booker = user?.roles?.booker && !user?.roles?.manager ? user : undefined;
    const lockTalentCards = !booker;

    // console.log(`lockTalentCards`, lockTalentCards);

    const filter = {
        ...defaultFilter,
        ...getBookerFilterOptions(booker),
        listId: listId || "",
        lockTalentCards
    };

    // console.log(`TJN NewBooking:getInitialState`, filter);

    return {
        talent: [],
        lists: [],
        booker: booker,
        campaigns: [],
        filter: filter,
        selectedTalent: {},
        totalTiktokFollowers: 0,
        totalInstagramFollowers: 0,
        totalPrice: 0,
        step: 'list',
        submissionStatus: undefined,
        showBrandAlert: true,
        creedModalOpen: false,
        creedModalShown: false,
        showMobileSummary: false,
    } as NewBookingState;
}

function newBookingStateReducer(state: NewBookingState, action: NewBookingStateAction): NewBookingState {
    // console.log(`action`, state.filter.lockTalentCards, action);

    switch (action.type) {
        case 'setStatePartial': {
            return { ...state, ...action.payload } as NewBookingState;
        }
        // case 'addTalent': {
        //     const { id, bidPrice, isRec } = action;
        //     const talent = state.talent;

        //     const newSelectedTalent: SelectedTalent = JSON.parse(JSON.stringify(state.selectedTalent));
        //     // newSelectedTalent[id] = bidPrice;
        //     newSelectedTalent[id] = { ...newSelectedTalent[id], price: bidPrice };

        //     // TJN TO-DO: RECS - restore?
        //     // // Don't update recs if we're just changing the bid price on a selected creator...
        //     // if (Object.keys(selectedTalent).length !== Object.keys(newSelectedTalent).length) {
        //     //     updateRecs(newSelectedTalent);
        //     // }
        //     const newTotals = calculateTotals(newSelectedTalent, talent);


        //     const tal = talent.find((tal) => tal.id === id);
        //     analyticsService().logEvent("Selected Talent", {
        //         talentId: id,
        //         tiktokUser: tal?.tiktokUser,
        //         instagramUser: tal?.instagramUser,
        //         bidPrice: bidPrice,
        //         recommendation: isRec || false,
        //     });

        //     return { ...state, selectedTalent: newSelectedTalent, ...newTotals };
        // }
        case 'addTalent': {
            const { id, selectedTalentItem } = action;
            const talent = state.talent;

            const newSelectedTalent: SelectedTalent = JSON.parse(JSON.stringify(state.selectedTalent));
            newSelectedTalent[id] = { ...newSelectedTalent[id], ...selectedTalentItem };

            // TJN TO-DO: RECS - restore?
            // // Don't update recs if we're just changing the bid price on a selected creator...
            // if (Object.keys(selectedTalent).length !== Object.keys(newSelectedTalent).length) {
            //     updateRecs(newSelectedTalent);
            // }
            const newTotals = calculateTotals(newSelectedTalent, talent);


            const tal = talent.find((tal) => tal.id === id);
            analyticsService().logEvent("Selected Talent", {
                talentId: id,
                tiktokUser: tal?.tiktokUser,
                instagramUser: tal?.instagramUser,
                bidPrice: selectedTalentItem.price,
                recommendation: selectedTalentItem.isRec || false,
            });

            return { ...state, selectedTalent: newSelectedTalent, ...newTotals };
        }
        case 'removeTalent': {
            const { id } = action;
            const talent = state.talent;
            const newSelectedTalent = JSON.parse(JSON.stringify(state.selectedTalent));
            delete newSelectedTalent[id];

            const newTotals = calculateTotals(newSelectedTalent, talent);
            // updateRecs(newSelectedTalent); // TJN TO-DO: RECS - restore?

            const tal = talent.find((tal) => tal.id === id);
            analyticsService().logEvent("Deselected Talent", {
                talentId: id,
                tiktokUser: tal?.tiktokUser,
                instagramUser: tal?.instagramUser
            });

            return { ...state, selectedTalent: newSelectedTalent, ...newTotals };
        }
        case 'navigateToStep': {
            const step = action.step;
            analyticsService().logEvent(step === 'list' ? "Viewed Booking Talent List" : "Viewed Booking Form");
            return { ...state, step } as NewBookingState;
        }
        case 'setBooker': {
            const { booker } = action;

            const filter = {
                ...state.filter,
                ...getBookerFilterOptions(booker, state.filter.lockCurrencyFromList ? state.filter.currency : undefined),
                lockTalentCards: !booker
            };

            let creedModalOpen = false;
            if (booker?.clientId === 'QgqnbwvLTq8GF3cQX7i5') {
                creedModalOpen = true;
            }

            return { ...state, booker, filter, creedModalOpen };
        }
        case 'setLists': {
            const { listId, lists } = action;
            let newLists: List[] = [];

            if (listId) {
                newLists = [...lists.map((list) => { return { ...list, active: false } })]; // active: true
            } else {
                newLists = [{ id: "", name: "All", active: true }, ...lists];
            }

            return { ...state, lists: newLists }; // , filter: { ...state.filter, lockCampaignPlatform: !!listId }
        }
        case 'setSubmissionStatus': {
            const submissionStatus = action.status;
            return { ...state, submissionStatus };
        }
        case 'updateFilter': {
            return {
                ...state,
                filter: {
                    ...state.filter,
                    ...action.filterPartial
                } as TalFilter
            };
        }
    }
}

function calculateTotals(selectedTalent: SelectedTalent, talent: Talent[]) {
    let totalPrice = 0;
    let totalTiktokFollowers = 0;
    let totalInstagramFollowers = 0;

    for (const selectedTal of Object.values(selectedTalent)) {
        totalPrice += selectedTal.price || 0;
    }

    for (const selectedTalId of Object.keys(selectedTalent)) {
        const curTal = talent.filter((tal: Talent) => tal.id === selectedTalId)[0];
        if (curTal?.ttFollowers) totalTiktokFollowers += curTal.ttFollowers;
        if (curTal?.igFollowers) totalInstagramFollowers += curTal.igFollowers;
    }

    return {
        totalPrice,
        totalTiktokFollowers,
        totalInstagramFollowers,
    };
}

function getBookerFilterOptions(booker: User | undefined, listCurrency?: Currency) {
    const filter: Partial<TalFilter> = {};

    if (booker?.clientWideSettings?.currency && listCurrency && booker?.clientWideSettings?.currency !== listCurrency) {
        if (listCurrency) {
            alert(`List currency is locked to ${listCurrency}. Ignoring booker currency of ${booker.clientWideSettings.currency}.`);
        } else {
            filter.currency = booker.clientWideSettings.currency;
            filter.lockCurrencyFromBooker = true;
        }
    } else {
        filter.lockCurrencyFromBooker = false;
    }

    if (booker?.campaignType === "Song") {
        filter.campaignType = "Song";
        filter.showCampaignTypeSelect = false;
    } else if (booker?.campaignType === "Brand") {
        filter.campaignType = "Brand";
        filter.showCampaignTypeSelect = false;
    } else if (booker?.campaignType === "Multi") {
        // filter.campaignType -- keep at whatever it's already set to
        filter.showCampaignTypeSelect = true;
    } else  /* null/undefined */ {
        filter.campaignType = "Song";
        filter.showCampaignTypeSelect = false;
    }

    return filter;
}

function calculateTalentWithPrices(talent: Talent[], poundsToDollars: number, filter: TalFilter, listTalent: SelectedTalent) {
    //console.log("FUNCTION: recalculateTalentPrices", talent.length, poundsToDollars);

    let talentWithPrice = talent.map((tal: Talent) => {
        const localPrices = grailUtil.getLocalPrices(tal, poundsToDollars, filter.currency);
        const defaultPrice = grailUtil.getDefaultGuidePrice(localPrices || {}, filter.campaignPlatform, filter.campaignType || "Song");
        const listCustomPrice = listTalent[tal.id]?.price || null;

        return {
            ...tal,
            defaultPrice: defaultPrice,
            listCustomPrice,
            localPrices: grailUtil.getLocalPrices(tal, poundsToDollars, filter.currency)
        } as Talent
    });

    return talentWithPrice;
}

async function buildCampaignAndBookings(formData: FieldValues, authenticatedUser: User | undefined, state: NewBookingState, talent: Talent[]) {
    const { booker, totalPrice, selectedTalent, filter: { campaignType, currency, campaignPlatform } } = state;

    let sendCampaignReceipt = true;
    let sendBookingOpp = true;

    if (authenticatedUser?.roles?.manager) {
        sendCampaignReceipt = false;
        sendBookingOpp = false;
    }
    if (authenticatedUser?.roles?.brandTeam) {
        sendCampaignReceipt = formData.sendCampaignReceipt === "true" ? true : false;
        sendBookingOpp = formData.sendBookingOpp === "true" ? true : false;
    }

    const theCampaign: Campaign = {
        id: formData.campaign.id || generateNewDocId("campaigns"), // simplify submitTheCampaign
        booker: {
            ...(booker?.airtableBookerId && { airtableBookerId: booker?.airtableBookerId }),
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            company: formData.company,
            phone: formData.phone,
            uid: booker?.id,
        },
        ...(formData.boostInterest && { boostInterest: true }),
        // bookings: bookings,
        campaignName: formData.campaign.label,
        campaignType,
        campaignPlatform: state.filter.campaignPlatform,
        lastUpdateByManager: authenticatedUser?.roles?.manager ? true : false,
        currency,
        details: campaignPlatform + " -- " + formData.details || '',
        // file: formData.file,
        originalQuote: totalPrice,
        sendBookingOpp: sendBookingOpp,
        sendCampaignReceipt: sendCampaignReceipt,
        soundUrl: formData?.soundUrl?.length ? grailUtil.removeParamsFromURL(formData.soundUrl) : null,
        uid: booker?.id,
        ...((formData.email === 'timothy.noetzel@gmail.com' || process.env.NODE_ENV === 'development') && { isTest: true }),
    };

    // const theUser = {
    //     id: user?.id,
    //     firstName: formData.firstName,
    //     lastName: formData.lastName,
    //     email: formData.email,
    //     company: formData.company,
    //     phone: formData.phone,
    //     currency: userCurrency,
    // }
    // TJN TO-DO: MAYBE...
    // async function updateTheUser(data: any) {
    //     const res = await updateUser(data);
    //     console.log(`updateTheUser res`, res);
    // }

    const newBookings = talent.filter((tal) => selectedTalent[tal.id]).map((tal): Booking => {
        return {
            talentId: tal.id,
            price: selectedTalent[tal.id].price,
            talentName: tal.tiktokUser || tal.instagramUser,
            managerIds: tal.managerIds || [],
            airtableClientId: booker?.airtableClientId || '',
            ...(tal.airtableManagerIds && { airtableManagerIds: tal.airtableManagerIds }),
            id: generateNewDocId("bookings"),
            bookerId: booker?.id,
            ...(booker?.airtableBookerId && { airtableBookerId: booker?.airtableBookerId }),
            campaignId: theCampaign.id,
            campaignType,
            campaignPlatform: state.filter.campaignPlatform,
            ...(booker?.clientId && { clientId: booker.clientId }),
            ...(booker?.company && { clientName: booker.company }),
            creatorId: authenticatedUser?.id,
            createdByManager: authenticatedUser?.roles?.manager ? true : false,
            lastUpdateByManager: authenticatedUser?.roles?.manager ? true : false,
            currency,
            ...((formData.email === 'timothy.noetzel@gmail.com' || process.env.NODE_ENV === 'development') && { isTest: true }),
            monthDue2: grailUtil.getCurrentMonthDue(),
        }
    });

    // console.log(`submitBooking campaign:`, theCampaign);
    // console.log(`submitBooking newBookings:`, newBookings);

    return {
        campaign: theCampaign,
        bookings: newBookings
    };
}

async function createBooker(booker: User, authenticatedUser?: User) {

    let clientId = booker.clientId;

    if (!clientId) {
        const clientRes = await createClient({
            name: booker.company!,
            primaryBookerEmail: booker.email || '',
            primaryBookerId: booker.id || '',
            primaryManagerId: authenticatedUser?.id || '',
            airtablePrimaryManagerId: authenticatedUser?.managerAirtableId || '',
        });

        if (clientRes.success) {
            clientId = clientRes.result;
        }
    }

    const res = await createUser({ ...booker, clientId: clientId, verified: true, roles: { booker: true } });

    analyticsService().logEvent("Created Booker", {
        ...booker,
        clientId: clientId
    });

    return res;
}