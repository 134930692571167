import Chip from "@mui/material/Chip";

function StatusCell(params: any) {
    const status = params.value?.toLowerCase();
    if (status === "baddebt") {
        return <Chip label={"Bad Debt"} color="error" variant="outlined" size="small" />
    } else if (status === "cancelled") {
        return <Chip label={status} color="error" variant="outlined" size="small" />
    } else if (status === "declined") {
        return <Chip label={status} color="error" size="small" />
    } else if (status === 'draft' || status === 'posted') {
        return <Chip label={status} color="warning" size="small" />
    } else if (status === 'confirmed') {
        return <Chip label={status} color="primary" size="small" />
    } else if (status === 'approved') {
        return <Chip label={status} color="success" size="small" />
    } else {
        return <>{status}</>

    }
}

export default StatusCell;